import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import { useSelector, useDispatch } from 'react-redux';
import { setButtonVibrate } from 'src/store/ui/actions';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  iconButton: {
    backgroundColor: '#32353A',
    color: 'white',
    padding: '12px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#ff6b00',
      color: '#fff',
    },
    margin: theme.spacing(1),
  },
  calendarIcon: {
    fontSize: '24px',
  },
  '@keyframes vibrate': {
    '0%': { transform: 'translate(0)', boxShadow: '0 0 0 0 rgba(255, 0, 0, 0.7)' },
    '20%': { transform: 'translate(-2px, 2px)' },
    '40%': { transform: 'translate(-2px, -2px)' },
    '60%': { transform: 'translate(2px, 2px)' },
    '80%': { transform: 'translate(2px, -2px)' },
    '100%': { transform: 'translate(0)', boxShadow: '0 0 0 10px rgba(255, 0, 0, 0)' }
  },
  '@keyframes glow': {
    '0%': { 
      border: '2px solid rgba(255, 0, 0, 0.2)',
      backgroundColor: '#32353A'
    },
    '50%': { 
      border: '2px solid rgba(255, 0, 0, 0.8)',
      backgroundColor: '#d32f2f'
    },
    '100%': { 
      border: '2px solid rgba(255, 0, 0, 0.2)',
      backgroundColor: '#32353A'
    }
  },
  vibrateAnimation: {
    animation: '$vibrate 0.3s linear infinite, $glow 1.5s ease-in-out infinite',
    '&:hover': {
      backgroundColor: '#d32f2f',
    }
  }
}));

export default function NewCustomButton({ clientId, therapistId, role }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const state = useSelector(state => state);
  // console.log('Entire Redux State:', state);
  // console.log('clientId:', clientId);
  // console.log('therapistId:', therapistId);
  // console.log('role:', role);
  
  const isVibrate = useSelector(state => state?.ui?.isButtonVibrate);
  // const isVibrate = true
  // console.log('UI State:', state?.ui);
  // console.log('isButtonVibrate:', isVibrate);

  const handleOpen = () => {
    setOpen(true);
    // Disable vibration effect when clicking the button
    dispatch(setButtonVibrate(false));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.buttonContainer}>
      <Tooltip title="Schedule Appointment" placement="top">
        <IconButton
          className={`${classes.iconButton} ${isVibrate ? classes.vibrateAnimation : ''}`}
          onClick={handleOpen}
          data-testid="calendar-button"
        >
          <CalendarMonthIcon className={classes.calendarIcon} />
        </IconButton>
      </Tooltip>
      <CustomModal 
        open={open} 
        handleClose={handleClose} 
        clientId={clientId}
        therapistId={therapistId}
        role={role}
      />
    </div>
  );
}

NewCustomButton.propTypes = {
};
