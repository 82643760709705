import React, { useState, useEffect, useContext } from "react";
import { Container } from "reactstrap";
import UserContext from "src/context/UserContext";
import { Role } from "src/models/Role";
import axios from "axios";
import { environment } from "src/environment/environment";

// CSS inline cho Tailwind classes
const styles = {
  badge: {
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem',
    fontSize: '0.75rem',
    fontWeight: '500',
    display: 'inline-block'
  },
  badgeBlue: {
    backgroundColor: '#dbeafe',
    color: '#1e40af'
  },
  badgeGreen: {
    backgroundColor: '#dcfce7',
    color: '#166534'
  },
  badgeRed: {
    backgroundColor: '#fee2e2',
    color: '#b91c1c'
  },
  badgeOrange: {
    backgroundColor: '#ffedd5',
    color: '#c2410c'
  },
  badgeGray: {
    backgroundColor: '#f9fafb',
    color: '#6b7280'
  },
  bgFafafa: {
    backgroundColor: '#fafafa'
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: '1.5rem',
    marginBottom: '2rem'
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    gap: '1rem',
    marginBottom: '1.5rem',
    alignItems: 'flex-end'
  },
  inputField: {
    border: '1px solid #e5e7eb',
    borderRadius: '0.25rem',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'white'
  },
  button: {
    backgroundColor: '#f97316',
    color: 'white',
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    cursor: 'pointer'
  },
  buttonHover: {
    backgroundColor: '#ea580c'
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    padding: '1rem',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse' as 'collapse',
    fontSize: '0.875rem',
    color: '#1f2937'
  },
  tableHeader: {
    borderBottom: '1px solid #e5e7eb',
    padding: '0.5rem 0.75rem',
    textAlign: 'left' as 'left',
    fontWeight: '600'
  },
  tableCell: {
    padding: '0.5rem 0.75rem',
    borderBottom: '1px solid #f3f4f6'
  },
  tableRowEven: {
    backgroundColor: 'white'
  },
  tableRowOdd: {
    backgroundColor: '#f9fafb'
  }
};

interface AggregatedMetrics {
  newSignups: number;
  scheduledAppointments: number;
  completedAppointments: number;
  missedAppointments: number;
  churnedClients: number;
  totalSessions: number;
  sessionsForNewClients: number;
}

interface ClientData {
  id: string;
  name: string;
  phoneNumber: string;
  upcomingAppointment: string | null;
  therapist: string;
  state: string;
  dateOnboarded: string;
  lastTimeInteractRingCentral: string;
  lastTimeInteractTwilo: string;
  weeksOnPlatform: number;
  sessionsCompleted: number;
  missedSessions: number;
  weeklyStatus: Record<string, string>;
}

interface Therapist {
  id: string;
  name: string;
}

// const API_BASE_URL = "http://localhost:8000";
const API_BASE_URL = `${environment.api_url}/api/auth`;


// Function to calculate relative days (e.g., 1 day ago)
const getRelativeDays = (dateString: string): string => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  const currentDate = new Date();
  
  // Reset time portion for accurate day calculation
  date.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);
  
  // Calculate difference in days
  const diffTime = currentDate.getTime() - date.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  if (diffDays === 0) {
    return 'Today';
  } else if (diffDays === 1) {
    return '1 day ago';
  } else {
    return `${diffDays} days ago`;
  }
};

const NewAdminDashboard: React.FC = () => {
  const [loggedUser] = useContext(UserContext);
  const [startDate, setStartDate] = useState("2022-01-01");
  const [endDate, setEndDate] = useState("2025-12-31");
  const [selectedState, setSelectedState] = useState("");
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [page, setPage] = useState(1);

  const [metrics, setMetrics] = useState<AggregatedMetrics | null>(null);
  const [clients, setClients] = useState<ClientData[]>([]);
  const [weekLabels, setWeekLabels] = useState<string[]>([]);
  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Prepare headers with token
  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  };

  // Fetch aggregated metrics
  const fetchMetrics = () => {
    setIsLoading(true);
    fetch(`${API_BASE_URL}/metrics/aggregated?start_date=${startDate}&end_date=${endDate}`, {
      headers: getAuthHeaders()
    })
      .then((res) => res.json())
      .then((data) => {
        setMetrics(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching aggregated metrics:", err);
        setIsLoading(false);
      });
  };

  // Fetch client data with filters and pagination
  const fetchClients = (reset: boolean = false) => {
    setIsLoading(true);
    let url = `${API_BASE_URL}/metrics/clients?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=25`;
    if (selectedState) url += `&stateFilter=${selectedState}`;
    if (selectedTherapist) url += `&therapistFilter=${selectedTherapist}`;
    if (selectedStatus) url += `&statusFilter=${selectedStatus}`;

    fetch(url, {
      headers: getAuthHeaders()
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Clients data:", data);
        
        // Process client data based on the returned format
        let clientsData: ClientData[] = [];
        
        if (Array.isArray(data)) {
          clientsData = data;
        } else if (data && data.data && Array.isArray(data.data)) {
          clientsData = data.data;
        } else if (data && data.success && data.data) {
          clientsData = Array.isArray(data.data) ? data.data : [];
        } else {
          console.error("Client data is not in expected format:", data);
          clientsData = [];
        }
        
        if (reset) {
          setClients(clientsData);
        } else {
          setClients((prev) => [...prev, ...clientsData]);
        }
        
        // Set weekLabels only when data is available
        if (clientsData.length > 0 && clientsData[0].weeklyStatus) {
          setWeekLabels(Object.keys(clientsData[0].weeklyStatus));
        } else {
          setWeekLabels([]);
        }
        
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching clients data:", err);
        setIsLoading(false);
        setClients([]);
      });
  };

  // Fetch therapists for the dropdown
  const fetchTherapists = () => {
    fetch(`${API_BASE_URL}/metrics/therapists`, {
      headers: getAuthHeaders()
    })
      .then((res) => res.json())
      .then((data) => {
        // Check returned data
        console.log("Therapists data:", data);
        
        // Check and ensure therapists is an array
        if (data && data.data && Array.isArray(data.data)) {
          setTherapists(data.data);
        } else if (Array.isArray(data)) {
          setTherapists(data);
        } else if (data && data.success) {
          // Case when API returns format {success: true, data: [...]}
          setTherapists(Array.isArray(data.data) ? data.data : []);
        } else {
          console.error("Therapists data is not an array:", data);
          setTherapists([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching therapists:", err);
        setTherapists([]);
      });
  };

  // Initial load
  useEffect(() => {
    setPage(1);
    fetchMetrics();
    fetchClients(true);
    fetchTherapists();
  }, []);

  // When page changes (and page > 1), fetch additional clients
  useEffect(() => {
    if (page > 1) {
      fetchClients(false);
    }
  }, [page]);

  // Handler for Search button: reset page and re-fetch data
  const handleSearch = () => {
    setPage(1);
    fetchMetrics();
    fetchClients(true);
  };

  // Handler for Load More button: increment page number
  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  function renderStatusBadge(status: string | undefined) {
    if (!status) return <span>-</span>;
    
    let badgeStyle = {...styles.badge};
    
    if (status === "Not Onboarded") {
      badgeStyle = {...badgeStyle, ...styles.badgeBlue};
    } else if (status === "Successful") {
      badgeStyle = {...badgeStyle, ...styles.badgeGreen};
    } else if (status === "Missed") {
      badgeStyle = {...badgeStyle, ...styles.badgeRed};
    } else if (status === "Rescheduled") {
      badgeStyle = {...badgeStyle, ...styles.badgeOrange};
    } else if (status === "No Appointment") {
      badgeStyle = {...badgeStyle, ...styles.badgeGray};
    }

    return (
      <span style={badgeStyle}>
        {status}
      </span>
    );
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div style={styles.bgFafafa}>
            {/* Page Header */}
            <div style={{marginBottom: '1.5rem'}}>
              <h1 style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Client Dashboard</h1>
              <p style={{fontSize: '0.875rem', color: '#6b7280', marginTop: '0.25rem'}}>Analytics Overview</p>
            </div>

            {/* Filter Row */}
            <div style={styles.flexWrap}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <label style={{fontSize: '0.875rem', color: '#6b7280'}}>Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={styles.inputField}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <label style={{fontSize: '0.875rem', color: '#6b7280'}}>End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={styles.inputField}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <label style={{fontSize: '0.875rem', color: '#6b7280'}}>State</label>
                <select
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                  style={styles.inputField}
                >
                  <option value="">All States</option>
                  <option value="North Carolina">North Carolina</option>
                  <option value="Georgia">Georgia</option>
                </select>
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <label style={{fontSize: '0.875rem', color: '#6b7280'}}>Therapist</label>
                <select
                  value={selectedTherapist}
                  onChange={(e) => setSelectedTherapist(e.target.value)}
                  style={styles.inputField}
                >
                  <option value="">All Therapists</option>
                  {Array.isArray(therapists) && therapists.map((t) => (
                    <option key={t.id} value={t.name}>{t.name}</option>
                  ))}
                </select>
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <label style={{fontSize: '0.875rem', color: '#6b7280'}}>Status</label>
                <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  style={styles.inputField}
                >
                  <option value="">All Statuses</option>
                  <option value="Not Onboarded">Not Onboarded</option>
                  <option value="Successful">Successful</option>
                  <option value="Missed">Missed</option>
                  <option value="Rescheduled">Rescheduled</option>
                  <option value="No Appointment">No Appointment</option>
                </select>
              </div>
              <button
                onClick={handleSearch}
                style={styles.button}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.button.backgroundColor}
              >
                Search
              </button>
            </div>

            {isLoading && (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem'}}>
                <div style={{fontSize: '1.125rem', fontWeight: '500', color: '#374151'}}>Loading...</div>
              </div>
            )}

            {/* Top Metrics Section */}
            {metrics && (
              <div 
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', 
                  gap: '1.5rem',
                  marginBottom: '2rem'
                }}
              >
                <div style={styles.card}>
                  <p style={{fontSize: '0.875rem', color: '#6b7280', marginBottom: '0.25rem'}}>New Signups</p>
                  <h2 style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#1f2937'}}>{metrics.newSignups}</h2>
                  <p style={{fontSize: '0.75rem', color: '#16a34a', marginTop: '0.25rem'}}>+5.4% from last month</p>
                </div>
                <div style={styles.card}>
                  <p style={{fontSize: '0.875rem', color: '#6b7280', marginBottom: '0.25rem'}}>Appointments</p>
                  <div style={{color: '#1f2937'}}>
                    <p>Scheduled: {metrics.scheduledAppointments}</p>
                    <p>Completed: {metrics.completedAppointments}</p>
                    <p>Missed: {metrics.missedAppointments}</p>
                  </div>
                </div>
                <div style={styles.card}>
                  <p style={{fontSize: '0.875rem', color: '#6b7280', marginBottom: '0.25rem'}}>Churn & Sessions</p>
                  <div style={{color: '#1f2937'}}>
                    <p>Churned Clients: {metrics.churnedClients}</p>
                    <p>Total Sessions: {metrics.totalSessions}</p>
                    <p>Sessions for New Clients: {metrics.sessionsForNewClients}</p>
                  </div>
                </div>
              </div>
            )}

            {/* Client Table Section */}
            <div style={styles.card}>
              <div style={{overflowX: 'auto'}}>
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th style={styles.tableHeader}>Client Name</th>
                      <th style={styles.tableHeader}>Phone Number</th>
                      <th style={styles.tableHeader}>Upcoming Appointment</th>
                      <th style={styles.tableHeader}>Therapist</th>
                      <th style={styles.tableHeader}>State</th>
                      <th style={styles.tableHeader}>Date Onboarded</th>
                      <th style={styles.tableHeader}>Last RingCentral</th>
                      <th style={styles.tableHeader}>Last Twilio</th>
                      <th style={styles.tableHeader}>Weeks on Platform</th>
                      <th style={styles.tableHeader}>Sessions Completed</th>
                      <th style={styles.tableHeader}>Missed Sessions</th>
                      {weekLabels.map((label) => (
                        <th key={label} style={styles.tableHeader}>
                          {label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(clients) && clients.map((client, idx) => (
                      <tr
                        key={client.id}
                        style={idx % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd}
                      >
                        <td style={{...styles.tableCell, fontWeight: '500'}}>{client.name}</td>
                        <td style={styles.tableCell}>{client.phoneNumber}</td>
                        <td style={styles.tableCell}>
                          {client.upcomingAppointment || "N/A"}
                        </td>
                        <td style={styles.tableCell}>{client.therapist}</td>
                        <td style={styles.tableCell}>{client.state}</td>
                        <td style={styles.tableCell}>{client.dateOnboarded}</td>
                        <td style={styles.tableCell}>{getRelativeDays(client.lastTimeInteractRingCentral)}</td>
                        <td style={styles.tableCell}>{getRelativeDays(client.lastTimeInteractTwilo)}</td>
                        <td style={styles.tableCell}>{client.weeksOnPlatform}</td>
                        <td style={styles.tableCell}>{client.sessionsCompleted}</td>
                        <td style={styles.tableCell}>{client.missedSessions}</td>
                        {weekLabels.map((label) => (
                          <td key={label} style={styles.tableCell}>
                            {renderStatusBadge(client.weeklyStatus[label])}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Load More Button */}
            <div style={{marginTop: '1rem', display: 'flex', justifyContent: 'center'}}>
              <button
                onClick={handleLoadMore}
                style={styles.button}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.button.backgroundColor}
              >
                View More
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewAdminDashboard; 