import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import CoverImage from '../../../../../../assets/images/end-meeting.png'
import Animation from '../../../../../../assets/images/end-meeting-animation.gif'
import CorrectMark from '../../../../../../assets/images/right mark.png'
import styles from './styles';
import SessionFeedbackModal from 'src/pages/Popup/SessionFeedBackModal';
import UserContext from "src/context/UserContext";
import { VonageServices } from "../../../vonageServices";
import { toast } from "react-toastify";
import { Modal, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Role } from 'src/models/Role';
import axios from 'axios';
import { Util } from 'src/Util';

export default function EndCall() {
  const history = useHistory();
  const [recordings, setRecordings] = useState(null);
  const classes = styles();
  const { sessionId } = useParams();
  const [isOpenSessionFeedbackModal, setIsOpenSessionFeedbackModal] = useState(true);
  const [loggedUser] = useContext(UserContext);
  const [showTherapistModal, setShowTherapistModal] = useState(false);
  const [isSubmittingTherapistModal, setIsSubmittingTherapistModal] = useState(false);
  const [therapistModalSubmitted, setTherapistModalSubmitted] = useState(false);
  
  // Form state
  const [formData, setFormData] = useState({
    serviceProvided: "",
    suicidalIdeation: "No",
    homicidalIdeation: "No",
    perceptualDisturbance: "No",
    nextSessionScheduled: "No",
    clinicalDirectorReviewRequired: "No",
    comments: ""
  });

  const [formErrors, setFormErrors] = useState({
    serviceProvided: false
  });
  
  // Direct handlers for radio buttons
  const handleRadioChange = (fieldName, value) => {
    console.log(`Manual radio change: ${fieldName} = ${value}`);
    setFormData({
      ...formData,
      [fieldName]: value
    });
  };

  useEffect(()=> {
    console.log("sessionId ", sessionId);
    // const popupTimeout = setTimeout(() => {
    //   window.location.href = "/dashboard";
    // }, 5000);

    // return () => clearTimeout(popupTimeout);

    // Debug code to check for overlay elements or event issues
    const debugRadioButtons = () => {
      console.log('Debug: Setting up event listeners for radio debugging');
      
      // Get all radio buttons in the form
      setTimeout(() => {
        const radioButtons = document.querySelectorAll('input[type="radio"]');
        console.log('Debug: Found radio buttons:', radioButtons.length);
        
        // Add click listeners directly to the document to catch all clicks
        document.addEventListener('click', (e) => {
          console.log('Debug: Document click detected at:', e.clientX, e.clientY);
          console.log('Debug: Target:', e.target);
        });
        
        // Add individual listeners to each radio button
        radioButtons.forEach((radio, index) => {
          console.log(`Debug: Radio button ${index}:`, radio);
          
          // Add a direct click listener
          radio.addEventListener('click', (e) => {
            console.log(`Debug: Direct click on radio ${index}`, e);
            e.stopPropagation(); // Prevent any parent handlers from canceling
          });
          
          // Check if the radio is within a positioned container
          const rect = radio.getBoundingClientRect();
          console.log(`Debug: Radio ${index} position:`, rect);
          
          // Add a visible border to verify position
          radio.style.border = '2px solid red';
          radio.style.zIndex = '9999';
        });
      }, 1000); // Wait for React to render the components
    };
    
    debugRadioButtons();
  }, [])

  const redirectToDashboard = () => {
    window.location.href = "/dashboard";
  };

  const closeSessionFeedbackModal = async (rate, feedback, isCloseBtnClicked) => {
    try {
      console.log("Close modal ", rate, feedback);
      setIsOpenSessionFeedbackModal(false);
      if (!isCloseBtnClicked) {
        const data = {
          meetingId: sessionId, 
          rate, 
          feedback, 
          createdBy: loggedUser?._id
        }
        const feedbackRes = await VonageServices.saveSessionFeedback(data);
        console.log(feedbackRes);
        if (feedbackRes.success) {
          toast.success(`Your feedback added successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        }
      }
      
      // Only redirect if not a THERAPIST
      if (loggedUser?.role !== Role.THERAPIST) {
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 3000);
      } else {
        // Show therapist modal
        setShowTherapistModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('Input change detected!', { name, value, currentValue: formData[name], element: e.target });
    // Log the entire form data before updating
    console.log('Current form data:', formData);
    
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Log after state update is scheduled
    console.log('Form data will be updated to include:', { [name]: value });
  }

  const validateForm = () => {
    const errors = {
      serviceProvided: !formData.serviceProvided
    };
    
    setFormErrors(errors);
    
    // Form is valid when no errors are true
    return !Object.values(errors).some(error => error);
  };

  const handleTherapistModalSubmit = async () => {
    // Validate form before submitting
    if (!validateForm()) {
      toast.error("Please select a service before submitting", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      return;
    }
    
    setIsSubmittingTherapistModal(true);
    try {
      // Add sessionId to formData for submission
      const postData = {
        ...formData,
        meetingId: sessionId, // Add meetingId to identify the call
        therapistId: loggedUser?._id // Add therapist ID
      };
      
      console.log("Submitting form data:", postData);
      
      // Call API using axios and Util.apiAuthUrl
      const url = Util.apiAuthUrl("submit-post-session");
      const result = await axios.post(url, postData);
      
      if (result.success) {
        setIsSubmittingTherapistModal(false);
        setTherapistModalSubmitted(true);
        
        toast.success(`Post-Session Form submitted successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        
        // Redirect after 3 seconds
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 3000);
      } else {
        throw new Error(result.error?.message || "Failed to submit form");
      }
    } catch (error) {
      console.log(error);
      setIsSubmittingTherapistModal(false);
      toast.error(`Error submitting data: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  }

  return (
    <div>
      <SessionFeedbackModal isOpen={isOpenSessionFeedbackModal} toggle={closeSessionFeedbackModal} />
      
      {/* Modal Post-Session Form cho THERAPIST */}
      <Modal isOpen={showTherapistModal} backdrop="static" keyboard={false} centered size="lg">
        <div className="modal-header">
          <h5 className="modal-title">Post-Session Form</h5>
        </div>
        <div className="modal-body">
          <Form>
            <FormGroup>
              <Label for="serviceProvided">Service Provided</Label>
              <Input
                type="select"
                name="serviceProvided"
                id="serviceProvided"
                value={formData.serviceProvided}
                onChange={handleInputChange}
                invalid={formErrors.serviceProvided}
              >
                <option value="">Select a service</option>
                <option value="90791">90791 - CCA</option>
                <option value="90837">90837 - Individual Therapy 53min+</option>
                <option value="90834">90834 - Individual Therapy 45min</option>
                <option value="90832">90832 - Individual Therapy 30min</option>
                <option value="90847">90847 - Couples Therapy WITH Client Present</option>
                <option value="90847">90847 - Family Therapy WITH Client Present</option>
                <option value="90846">90846 - Couples Therapy WITHOUT Client Present (Partner Alone)</option>
                <option value="90846">90846 - Family Therapy WITHOUT Client Present</option>
                <option value="90853">90853 - Group Therapy</option>
              </Input>
              {formErrors.serviceProvided && <div className="text-danger mt-1">Service selection is required</div>}
            </FormGroup>
            
            <div style={{ backgroundColor: "#f9f9f9", padding: "20px", marginBottom: "15px", borderRadius: "5px" }}>
              <FormGroup tag="fieldset">
                <Label>Suicidal Ideation</Label>
                <div style={{ display: "flex", gap: "30px" }}>
                  {/* Use button instead of radio for more direct interaction */}
                  <div 
                    onClick={() => handleRadioChange('suicidalIdeation', 'Yes')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.suicidalIdeation === 'Yes' ? '1px solid blue' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.suicidalIdeation === 'Yes' ? '#e6f7ff' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.suicidalIdeation === 'Yes' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: 'blue'
                        }} />
                      )}
                    </div>
                    Yes
                  </div>
                  
                  <div 
                    onClick={() => handleRadioChange('suicidalIdeation', 'No')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.suicidalIdeation === 'No' ? '1px solid #F47B20' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.suicidalIdeation === 'No' ? '#fff7e6' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.suicidalIdeation === 'No' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#F47B20'
                        }} />
                      )}
                    </div>
                    No
                  </div>
                </div>
              </FormGroup>
            </div>
            
            <div style={{ backgroundColor: "#f9f9f9", padding: "20px", marginBottom: "15px", borderRadius: "5px" }}>
              <FormGroup tag="fieldset">
                <Label>Homicidal Ideation</Label>
                <div style={{ display: "flex", gap: "30px" }}>
                  <div 
                    onClick={() => handleRadioChange('homicidalIdeation', 'Yes')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.homicidalIdeation === 'Yes' ? '1px solid blue' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.homicidalIdeation === 'Yes' ? '#e6f7ff' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.homicidalIdeation === 'Yes' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: 'blue'
                        }} />
                      )}
                    </div>
                    Yes
                  </div>
                  
                  <div 
                    onClick={() => handleRadioChange('homicidalIdeation', 'No')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.homicidalIdeation === 'No' ? '1px solid #F47B20' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.homicidalIdeation === 'No' ? '#fff7e6' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.homicidalIdeation === 'No' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#F47B20'
                        }} />
                      )}
                    </div>
                    No
                  </div>
                </div>
              </FormGroup>
            </div>
            
            <div style={{ backgroundColor: "#f9f9f9", padding: "20px", marginBottom: "15px", borderRadius: "5px" }}>
              <FormGroup tag="fieldset">
                <Label>Perceptual Disturbance</Label>
                <div style={{ display: "flex", gap: "30px" }}>
                  <div 
                    onClick={() => handleRadioChange('perceptualDisturbance', 'Yes')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.perceptualDisturbance === 'Yes' ? '1px solid blue' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.perceptualDisturbance === 'Yes' ? '#e6f7ff' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.perceptualDisturbance === 'Yes' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: 'blue'
                        }} />
                      )}
                    </div>
                    Yes
                  </div>
                  
                  <div 
                    onClick={() => handleRadioChange('perceptualDisturbance', 'No')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.perceptualDisturbance === 'No' ? '1px solid #F47B20' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.perceptualDisturbance === 'No' ? '#fff7e6' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.perceptualDisturbance === 'No' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#F47B20'
                        }} />
                      )}
                    </div>
                    No
                  </div>
                </div>
              </FormGroup>
            </div>
            
            <div style={{ backgroundColor: "#f9f9f9", padding: "20px", marginBottom: "15px", borderRadius: "5px" }}>
              <FormGroup tag="fieldset">
                <Label>Next Session Scheduled</Label>
                <div style={{ display: "flex", gap: "30px" }}>
                  <div 
                    onClick={() => handleRadioChange('nextSessionScheduled', 'Yes')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.nextSessionScheduled === 'Yes' ? '1px solid blue' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.nextSessionScheduled === 'Yes' ? '#e6f7ff' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.nextSessionScheduled === 'Yes' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: 'blue'
                        }} />
                      )}
                    </div>
                    Yes
                  </div>
                  
                  <div 
                    onClick={() => handleRadioChange('nextSessionScheduled', 'No')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.nextSessionScheduled === 'No' ? '1px solid #F47B20' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.nextSessionScheduled === 'No' ? '#fff7e6' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.nextSessionScheduled === 'No' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#F47B20'
                        }} />
                      )}
                    </div>
                    No
                  </div>
                </div>
              </FormGroup>
            </div>
            
            <div style={{ backgroundColor: "#f9f9f9", padding: "20px", marginBottom: "15px", borderRadius: "5px" }}>
              <FormGroup tag="fieldset">
                <Label>Clinical Director Review Required</Label>
                <div style={{ display: "flex", gap: "30px" }}>
                  <div 
                    onClick={() => handleRadioChange('clinicalDirectorReviewRequired', 'Yes')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.clinicalDirectorReviewRequired === 'Yes' ? '1px solid blue' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.clinicalDirectorReviewRequired === 'Yes' ? '#e6f7ff' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.clinicalDirectorReviewRequired === 'Yes' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: 'blue'
                        }} />
                      )}
                    </div>
                    Yes
                  </div>
                  
                  <div 
                    onClick={() => handleRadioChange('clinicalDirectorReviewRequired', 'No')} 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      border: formData.clinicalDirectorReviewRequired === 'No' ? '1px solid #F47B20' : '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: formData.clinicalDirectorReviewRequired === 'No' ? '#fff7e6' : 'transparent'
                    }}
                  >
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #999',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}>
                      {formData.clinicalDirectorReviewRequired === 'No' && (
                        <div style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#F47B20'
                        }} />
                      )}
                    </div>
                    No
                  </div>
                </div>
              </FormGroup>
            </div>
            
            <FormGroup>
              <Label for="comments">Comments (if necessary)</Label>
              <Input
                type="textarea"
                name="comments"
                id="comments"
                value={formData.comments}
                onChange={handleInputChange}
                rows="5"
              />
            </FormGroup>
          </Form>
        </div>
        <div className="modal-footer">
          <Button 
            color="primary" 
            block
            onClick={handleTherapistModalSubmit} 
            disabled={isSubmittingTherapistModal || therapistModalSubmitted}
            style={{ 
              backgroundColor: "#F47B20", 
              border: "none", 
              borderRadius: "4px",
              padding: "10px 15px"
            }}
          >
            {isSubmittingTherapistModal ? 'Submitting...' : therapistModalSubmitted ? 'Submitted' : 'Submit Form'}
          </Button>
        </div>
      </Modal>
      
      <div className={classes.container}>
        <div className={classes.meetingInfo}>
          <div className={classes.infoContainer}>
            <div className={classes.meetingInfoNew}>
              <img src={CoverImage} className={classes.coverImg} />
              <div className={classes.overlay}>
                <img src={Animation} />
              </div>
              <div className={classes.overlay}>
                <img src={CorrectMark} className={classes.correctImg} />
              </div>
            </div>
            <p className={classes.overlayText}>Meeting Successful</p>
            <p className={classes.overlayText2}>Thank you for taking this journey with us for your personal growth and well-being.</p>
            <div className={classes.btnContainer}>
              <button
                className="btn btn-primary mb-4"
                onClick={redirectToDashboard}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
